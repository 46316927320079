import { TokenNameEnum } from '@/common/enums';
import type { DirectSecp256k1HdWallet, OfflineSigner } from '@cosmjs/proto-signing';
import type { IgniteClient } from '@uniitio/ts-client/client';
import type { Module } from '@uniitio/ts-client/modules';

export const useAccountStore = defineStore('new-account-store', () => {
    const { toDollar } = useTokenConverter();

    const wallet = ref<UnitWallet>(null);
    const hdWallet = ref<DirectSecp256k1HdWallet>(null);

    const encodedHdWallet = useStorage('hd_w', null);
    const isAccountSecured = useStorage('ac_sec', false);
    const tokensPerPrices = useStorage<Record<TokenName, number>>('t_pps', {} as any);

    let updateWalletInterval: NodeJS.Timer = null;

    const portfolioCost = computed<number>(() => {
        let sum = 0;

        for (let dca of wallet.value.DCAList) {
            sum += dca.sumTokenAmount;
        }

        for (let balance of wallet.value.balances) {
            sum += toDollar({ name: balance.denom, WEI: parseFloat(balance.amount) });
        }

        return sum;
    });

    const createWallet = defineCosmRequest(async () => {
        const { DirectSecp256k1HdWallet } = await import('@cosmjs/proto-signing');

        const generatedObj = await DirectSecp256k1HdWallet.generate(12);
        const mnemonic = generatedObj.mnemonic;

        const [account] = await _getAccountsAndSave(mnemonic);
        isAccountSecured.value = false;

        _setWallet({ address: account.address });

        await executeFaucet();

        return account.address;
    });

    const importWallet = defineCosmRequest(async (seedPhrase: string, path: number = 0) => {
        const [account] = await _getAccountsAndSave(seedPhrase, path);
        isAccountSecured.value = true;
        _setWallet({ address: account.address });

        return account.address;
    });

    /**
     * Функция генерирует HDWallet из seedPhrase, сохраняя шифрованную версию в localStorage и возвращает массив аккаунтов/кошельков */
    async function _getAccountsAndSave(seedPhrase: string, path: number = 0) {
        const { DirectSecp256k1HdWallet, makeCosmoshubPath } = await import('@cosmjs/proto-signing');
        const { pincode } = useAuthStore();

        // const mnemonic = `repeat peace wait isolate bean dust such celery know cook pause predict cloud apart mammal around tiny day oil deny grant barrel abstract bitter`;

        hdWallet.value = await DirectSecp256k1HdWallet.fromMnemonic(seedPhrase, {
            hdPaths: makeCosmoshubPath[path],
            prefix: import.meta.env.VITE_COSMOS_ACCOUNT_ADDRESS_PREFIX,
        });

        encodedHdWallet.value = await hdWallet.value.serialize(pincode);

        return await hdWallet.value.getAccounts();
    }

    async function initWallet() {
        if (!wallet.value) {
            _initClearWallet();
        }

        if (encodedHdWallet.value) {
            const { DirectSecp256k1HdWallet } = await import('@cosmjs/proto-signing');

            const { pincode } = useAuthStore();

            hdWallet.value = await DirectSecp256k1HdWallet.deserialize(encodedHdWallet.value, pincode);

            const [account] = await hdWallet.value.getAccounts();

            _setWallet({ address: account.address });
        }

        if (!tokensPerPrices.value?.USDT) await updateTokenPerPrices();
    }

    const getBalances = defineRequest<[address: string], WalletBalance[]>(
        (address) => {
            const url = import.meta.env.VITE_COSMOS_API + 'cosmos/bank/v1beta1/balances/';

            return [url + address];
        },
        {
            afterHook: ({ success, data }) => {
                if (success) {
                    const balances = (data as unknown as CosmosBankV1Beta1BalancesDTO).balances;

                    for (let [id, balance] of Object.entries(balances)) {
                        // @ts-ignore
                        if (balance.denom == 'stake') {
                            const _id = parseInt(id);
                            balances.splice(_id, 1);
                        }
                    }

                    wallet.value.balances = balances;

                    return { data: balances };
                }
            },
        },
    );

    const getDCAList = defineRequest<[], WalletDCA[]>(
        () => {
            const url = import.meta.env.VITE_COSMOS_API + 'unit/unit/list_dca';

            return [url];
        },
        {
            afterHook: ({ success, data }) => {
                if (success) {
                    const _data = data as unknown as UnitchainUnitchainListDcaDTO;
                    const dcas = _data.dcas.filter((v) => v.creator == wallet.value.address);

                    for (let dca of dcas) {
                        dca.tokens.USDT.userViewTokenAmount = parseFloat(
                            dca.tokens.USDT.userViewTokenAmount,
                        ).toString();
                        dca.sumTokenAmount = getDCASumTokenAmount(dca);
                    }

                    wallet.value.DCAList = _data.dcas.filter((v) => v.creator == wallet.value.address);

                    return { data: wallet.value.DCAList };
                }
            },
        },
    );

    const getDCA = defineRequest<[id: string], WalletDCA>(
        (id) => {
            const url = import.meta.env.VITE_COSMOS_API + 'unit/unit/show_dca/';

            return [url + id];
        },
        {
            afterHook: ({ success, data }) => {
                if (success) {
                    const _data = data as unknown as UnitchainUnitchainDcaDTO;
                    _data.dca.sumTokenAmount = getDCASumTokenAmount(_data.dca, true);
                    _data.dca.tokens.USDT.userViewTokenAmount = parseFloat(
                        _data.dca.tokens.USDT.userViewTokenAmount,
                    ).toString();

                    return { data: _data.dca };
                }
            },
        },
    );

    function getDCASumTokenAmount(dca: WalletDCA, withoutUSDT: boolean = false) {
        const { toDollar } = useTokenConverter();

        let sum = 0;

        for (let [tokenName, token] of Object.entries(dca.tokens)) {
            if (withoutUSDT && tokenName == 'USDT') continue;

            const GWEI = parseFloat(token.userViewTokenAmount);
            const inUsdt = toDollar({ name: tokenName as TokenName, GWEI });

            sum += inUsdt;
        }

        return sum;
    }

    const updateTokenPerPrices = defineRequest(
        () => {
            const url = import.meta.env.VITE_COSMOS_API + 'unit/unit/get_price_list/';
            const pair = Object.keys(TokenNameEnum).map((v) => v + 'USDT');

            return [url + pair.join('%2C')];
        },
        {
            afterHook: ({ success, data }) => {
                if (success) {
                    const _data = data as unknown as UnitchainUnitchainGetPriceListDTO;

                    for (let v of Object.values(_data.prices)) {
                        const tokenKey = v.pair.replace('USDT', '');
                        const tokenValue = Number.parseFloat(v?.price) ?? 1;

                        if (!!tokenKey) {
                            tokensPerPrices.value[tokenKey] = isNaN(tokenValue) ? 1 : tokenValue;
                        }
                    }
                }
            },
        },
    );

    const sendToken = defineCosmRequest(async (value: number, toAddress: string) => {
        const { Module: CosmosBankV1Beta1 } = await import('@uniitio/ts-client/cosmos.bank.v1beta1');

        const { toWEI } = useTokenConverter();

        const client = await _createClient([CosmosBankV1Beta1], hdWallet.value);
        const amount = toWEI({ name: 'USDT', dollars: value });
        const fromAddress = wallet.value.address;

        await client.CosmosBankV1Beta1.tx.sendMsgSend({
            value: {
                amount: [
                    {
                        amount: amount.toString(),
                        denom: 'USDT',
                    },
                ],
                fromAddress,
                toAddress,
            },
            fee: {
                amount: [{ amount: '0', denom: 'UNT' }],
                gas: '200000',
            },
            memo: '',
        });
    });

    const deposit = defineCosmRequest(async (value: number, nftID: string) => {
        const { Module: UnitUnit } = await import('@uniitio/ts-client/unit.unit');

        const { toWEI } = useTokenConverter();

        const client = await _createClient([UnitUnit], hdWallet.value);
        const amount = toWEI({ name: 'USDT', dollars: value });

        await client.UnitUnit.tx.sendMsgDepositDca({
            value: {
                id: +nftID,
                amount: `${amount}USDT`,
                creator: wallet.value.address,
            },
            fee: {
                amount: [{ amount: '0', denom: 'UNT' }],
                gas: '200000',
            },
        });

        await getDCAList();
    });

    const withdraw = defineCosmRequest(async (nftID: string) => {
        const { Module: UnitUnit } = await import('@uniitio/ts-client/unit.unit');

        const client = await _createClient([UnitUnit], hdWallet.value);

        await client.UnitUnit.tx.sendMsgWithdrawDca({
            value: {
                creator: wallet.value.address,
                id: +nftID,
            },
            fee: {
                amount: [{ amount: '0', denom: 'UNT' }],
                gas: '200000',
            },
        });

        await getDCAList();
    });

    const mintNFT = defineCosmRequest(async (value: number) => {
        const { Module: UnitUnit } = await import('@uniitio/ts-client/unit.unit');

        const { toWEI } = useTokenConverter();

        const client = await _createClient([UnitUnit], hdWallet.value);
        const deposit = toWEI({ name: 'USDT', dollars: value });

        await client.UnitUnit.tx.sendMsgCreateDca({
            value: {
                creator: wallet.value.address,
                deposit: `${deposit}USDT`,
            },
            fee: {
                amount: [{ amount: '0', denom: 'UNT' }],
                gas: '200000',
            },
        });
    });

    const swap = defineCosmRequest(async (nftID: string) => {
        const { Module: UnitUnit } = await import('@uniitio/ts-client/unit.unit');

        const client = await _createClient([UnitUnit], hdWallet.value);

        await client.UnitUnit.tx.sendMsgInvestDca({
            value: {
                creator: wallet.value.address,
                id: +nftID,
                parts: 100,
            },
            fee: {
                amount: [{ amount: '0', denom: 'UNT' }],
                gas: '200000',
            },
        });

        await getDCAList();
    });

    async function _createClient<T extends Module | Module[] = []>(
        plugins: Parameters<typeof IgniteClient.plugin<T>>,
        signer?: OfflineSigner,
    ) {
        const { IgniteClient } = await import('@uniitio/ts-client/client');

        const Client = IgniteClient.plugin(...plugins);
        const client = new Client(
            {
                apiURL: import.meta.env.VITE_COSMOS_API,
                rpcURL: import.meta.env.VITE_COSMOS_RPC,
                prefix: import.meta.env.VITE_COSMOS_ACCOUNT_ADDRESS_PREFIX,
            },
            signer,
        );

        return client;
    }

    async function updateWallet() {
        if (wallet.value && encodedHdWallet.value) await getBalances(wallet.value.address);
        await updateTokenPerPrices();
    }

    const executeFaucet = defineRequest(
        () => {
            const url = import.meta.env.VITE_COSMOS_FAUCET_API;

            const { toWEI } = useTokenConverter();

            const amountUSDTWEI = toWEI({ name: 'USDT', GWEI: 1000 });

            return [
                url,
                {
                    method: 'post',
                    data: { address: wallet.value.address, coins: [`${amountUSDTWEI}USDT`, '100UNT'] },
                },
            ];
        },
        {
            afterHook: async ({ success }) => {
                if (success) {
                    await updateWallet();
                }
            },
        },
    );

    function _setWallet(setup: Partial<UnitWallet>) {
        if (!wallet.value) _initClearWallet();

        for (let key of Object.keys(setup)) {
            wallet.value[key] = setup[key];
        }
    }

    function _initClearWallet() {
        wallet.value = {
            address: '',
            balances: [],
            DCAList: [],
            hasNFT: false,
        };
    }

    if (!updateWalletInterval) {
        updateWalletInterval = setInterval(updateWallet, 10000);
    }

    return {
        wallet,
        hdWallet,
        encodedHdWallet,
        isAccountSecured,
        tokensPerPrices,
        portfolioCost,
        createWallet,
        importWallet,
        initWallet,
        getBalances,
        getDCAList,
        getDCA,
        updateTokenPerPrices,
        executeFaucet,
        getDCASumTokenAmount,
        sendToken,
        mintNFT,
        deposit,
        updateWallet,
        withdraw,
        swap,
    };
});
