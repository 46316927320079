<script setup lang="ts">
const { isAppLoggedIn, hasAppPincode } = storeToRefs(useAuthStore());

const screenConfig = computed<{ title: string; mode: PinCodeMode }>(() => {
    if (hasAppPincode.value) {
        return { title: 'Please confirm your PIN Code', mode: 'validation' };
    } else {
        return { title: 'Please create PIN Code', mode: 'newPassword' };
    }
});

function setAppLoggedIn() {
    isAppLoggedIn.value = true;
}

async function onValid() {
    const { initWallet } = useAccountStore();

    await initWallet();
    setAppLoggedIn();
}
</script>

<template>
    <div :class="$style.appSafeScreen">
        <VIcon name="ShieldExclamationIcon" type="solid" :size="24" :class="$style.icon" />
        <p :class="$style.title">{{ screenConfig.title }}</p>
        <ThePinCode :mode="screenConfig.mode" @valid="onValid" @create="setAppLoggedIn" />
    </div>
</template>

<style module lang="scss">
.appSafeScreen {
    @extend %flexColumn;

    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -65%);
    align-items: center;
}

.icon {
    margin-top: auto;
    margin-bottom: 15px;
    width: 24px;
}

.title {
    margin-top: 0;
    margin-bottom: 60px;
    font-size: 18px;
    font-weight: 700;
}
</style>
