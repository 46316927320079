<script setup lang="ts">
const route = useRoute();
</script>

<template>
    <div id="appLayout" :class="$style.appLayout">
        <HeaderWithSafeArea />
        <component :is="route.meta.layoutComponent">
            <slot />
        </component>
    </div>
</template>

<style lang="scss" module>
.appLayout {
    @extend %flexGrow1;
    @extend %flexColumn;

    position: relative;
    margin: 0 auto;
    max-width: 390px;
    width: 100%;
    height: 100vh;
    overflow: scroll;
}
</style>
