import axios from 'axios';

interface RequestResult<T> {
    success: boolean;
    data?: T;
    error: string;
}

type HookResult<T> = RequestResult<T> | void | Promise<RequestResult<T>> | Promise<void>;

export function defineRequest<FuncArgsT extends Array<any> = [], FuncResultT = void>(
    func: (...args: FuncArgsT) => Promise<Parameters<typeof axios>> | Parameters<typeof axios>,
    opts?: {
        errorText?: string;
        beforeHook?: (args: RequestResult<FuncResultT>) => Partial<HookResult<FuncResultT>>;
        afterHook?: (args: RequestResult<FuncResultT>) => Partial<HookResult<FuncResultT>>;
    },
) {
    async function request(...args: FuncArgsT): Promise<RequestResult<FuncResultT>> {
        const { afterHook, beforeHook, errorText } = opts ?? {};

        const result: RequestResult<FuncResultT> = {
            success: false,
            error: '',
        };

        if (beforeHook) {
            const beforeHookData = await beforeHook({ ...result });

            if (beforeHookData) Object.assign(result, beforeHookData);
        }

        try {
            const axiosOpts = await func(...args);
            const { data: axiosData } = await axios(...axiosOpts);

            result.data = axiosData;
            result.success = true;
        } catch (err) {
            console.error(errorText);
            console.error(err);

            result.success = false;
            result.error = errorText ?? err.message;
        }

        if (afterHook) {
            const afterHookData = await afterHook({ ...result });

            if (afterHookData) Object.assign(result, afterHookData);
        }

        return { ...result };
    }

    return request;
}
