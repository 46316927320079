import { AppAnimationDirection } from '@/common/enums';

type AppBackgroundTheme = 'topGradient' | 'bottomGradient' | 'dark';

export const useAppManageStore = defineStore('app-manage-store', () => {
    const currentThemes = ref<AppBackgroundTheme[]>([]);
    const currentAnimDirection = ref<AppAnimationDirection>(AppAnimationDirection.forward);

    function setAppBackgrounds(theme: AppBackgroundTheme[]) {
        currentThemes.value = theme;
    }

    function setPageAnimDirection(direction: keyof typeof AppAnimationDirection) {
        currentAnimDirection.value = AppAnimationDirection[direction];
    }

    return {
        currentThemes: readonly(currentThemes),
        currentAnimDirection: readonly(currentAnimDirection),
        setAppBackgrounds,
        setPageAnimDirection,
    };
});
