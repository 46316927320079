<script setup lang="ts">
const { setPincode, comparePincode } = useAuthStore();

const PASSWORD_SIZE = 6;

const props = withDefaults(
    defineProps<{
        mode?: PinCodeMode;
    }>(),
    {
        mode: 'validation',
    },
);

const emits = defineEmits<{
    (e: 'valid');
    (e: 'create');
}>();

const passwordValues = ref([]);
const isValid = ref(true);

const isFulledPassword = computed(() => passwordValues.value.length === PASSWORD_SIZE);
const joinedPasswordValues = computed(() => passwordValues.value.join(''));

function onEnterPassword(value: number) {
    isValid.value = true;

    if (passwordValues.value.length < PASSWORD_SIZE) passwordValues.value.push(value);

    if (isFulledPassword.value) executeLogic();
}

function onDeletePassword() {
    if (!isValid.value) {
        passwordValues.value = [];
    } else {
        passwordValues.value.pop();
    }

    isValid.value = true;
}

async function validate() {
    const isPincodeValid = await comparePincode(joinedPasswordValues.value);

    if (isPincodeValid) {
        emits('valid');
    } else {
        isValid.value = false;
    }
}

async function create() {
    await setPincode(joinedPasswordValues.value);
    emits('create');
}

async function executeLogic() {
    if (props.mode === 'validation') {
        await validate();
    } else {
        await create();
    }
}
</script>

<template>
    <div :class="$style.pinCode">
        <div :class="$style.indicatorWrap">
            <span
                v-for="i in PASSWORD_SIZE"
                :key="i"
                :class="[
                    $style.indicator,
                    passwordValues.length >= i && $style.indicatorSuccess,
                    !isValid && $style.indicatorFailed,
                ]"
            />
        </div>
        <div :class="$style.keyboard">
            <button
                v-for="(i, index) in 10"
                :key="i"
                type="button"
                @click="onEnterPassword(index)"
                :class="$style.keyboardBtn"
            >
                {{ index }}
            </button>
            <button type="button" @click="onDeletePassword" :class="$style.deleteBtn">
                <VIcon name="BackspaceIcon" type="outline" :size="40" :class="$style.deleteIcon" />
            </button>
        </div>
    </div>
</template>

<style module lang="scss">
.pinCode {
    @extend %flexColumn;

    align-items: center;
}

.indicatorWrap {
    margin-bottom: 110px;
    max-width: 236px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.indicator {
    width: 16px;
    height: 16px;
    border: 2px solid colors.$white-50;
    border-radius: 50%;
}

.indicatorSuccess {
    border-color: colors.$white;
    background-color: colors.$white;
}

.indicatorFailed {
    border-color: colors.$red;
    background-color: colors.$red;
    animation: failAnimation 0.2s ease-in forwards;
}

.keyboard {
    width: 290px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px 40px;
}

.keyboardBtn,
.deleteBtn {
    padding: 0;
    width: 70px;
    height: 70px;
    text-align: center;
    border: 2px solid colors.$white-50;
    border-radius: 50%;
    background-color: transparent;
    transition: all 0.1s linear;
    cursor: pointer;

    &:active {
        background-color: colors.$white;
        border-color: colors.$white;
        color: colors.$black;
    }
}

.keyboardBtn {
    font-size: 34px;
    line-height: 1.5;
    font-weight: 300;
    color: colors.$white;

    &:first-of-type {
        grid-row: 4/5;
        grid-column: 2/3;
    }
}

.deleteBtn {
    grid-column: 3/4;

    .deleteIcon {
        transform: translateX(-2px);
        width: 40px;
        height: 40px;
        stroke-width: 1px;
        stroke: colors.$white;
        transition: all 0.1s linear;
    }

    &:active {
        .deleteIcon {
            stroke: colors.$black;
        }
    }
}

@keyframes failAnimation {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(40%);
    }

    50% {
        transform: translateX(0);
    }

    75% {
        transform: translateX(-40%);
    }

    100% {
        transform: translateX(0);
    }
}
</style>
