<script setup lang="ts">
type IconName =
    | keyof typeof import('@heroicons/vue/24/solid')
    | keyof typeof import('@heroicons/vue/24/outline')
    | LocalIconName | TokenName;

const props = withDefaults(
    defineProps<{
        name: IconName;
        type?: 'solid' | 'outline' | 'local';
		dir?: string
        size?: number;
    }>(),
    {
        type: 'local',
        size: 20,
    },
);

const selectedIcon = computed(() => {
    if (props.type == 'local') {
		let dir = '';
		let name = '';

		if(props.dir) {
			dir = props.dir
			name = props.name
		} else {
			const [_dir, _name] = props.name.split('/');
			dir = _dir
			name = _name
		}

        if (!name) {
            return defineAsyncComponent(() => import(`../assets/icons/${dir}.svg`));
        } else {
            return defineAsyncComponent(() => import(`../assets/icons/${dir}/${name}.svg`));
        }
    } else {
        return defineAsyncComponent(
            () => import(`../../node_modules/@heroicons/vue/24/${props.type}/esm/${props.name}.js`),
        );
    }
});
</script>

<template>
    <component :is="selectedIcon" v-bind="$attrs" :class="$style.icon" :style="{ width: size + 'px' }" />
</template>

<style lang="scss" module>
.icon {
    flex-shrink: 0;
    height: auto;
}
</style>
