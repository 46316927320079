<script setup lang="ts">
import { useScroll } from '@vueuse/core';

const SCROLL_LEVEL = 60;

const appLayoutEl: Ref<HTMLElement> = ref(null);
const { y: yAxis } = useScroll(appLayoutEl);

const headerEl: Ref<HTMLElement> = ref(null);

const blurCss = computed(() => {
    const step = SCROLL_LEVEL / 15;
    const blur = yAxis.value / step;

    if (yAxis.value <= SCROLL_LEVEL) return `blur(${blur}px)`;
    else return `blur(15px)`;
});

const bgColorCss = computed(() => {
    const step = SCROLL_LEVEL / 0.6;
    const alpha = yAxis.value / step;

    if (yAxis.value <= SCROLL_LEVEL) return `rgba(0, 0, 0, ${alpha})`;
    else return `rgba(0, 0, 0, 0.6)`;
});

onMounted(() => {
    useAutoAnimateHeader(headerEl.value);
    appLayoutEl.value = document.getElementById('appLayout');
});
</script>

<template>
    <div :class="$style.headerContainer">
        <TheSafeArea />
        <div ref="headerEl" id="headerTarget" />
    </div>
</template>

<style lang="scss" module>
.headerContainer {
    position: sticky;
    top: 0;
    border-radius: 0 0 12px 12px;
    background-color: v-bind(bgColorCss);
    backdrop-filter: v-bind(blurCss);
    transition: all 0.15s linear;
    z-index: zindex.$sticky;
}
</style>
