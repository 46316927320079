<script setup lang="ts">
const { currentAnimDirection } = storeToRefs(useAppManageStore());

withDefaults(
    defineProps<{
        isAppear?: boolean;
    }>(),
    {
        isAppear: false,
    },
);
</script>

<template>
    <Transition mode="out-in" :name="currentAnimDirection" :appear="isAppear">
        <slot />
    </Transition>
</template>
