<script setup lang="ts">
const { isAppLoggedIn } = storeToRefs(useAuthStore());
</script>

<template>
    <div :class="$style.appContainer">
        <AppLayout>
            <template v-if="isAppLoggedIn">
                <RouterView v-slot="{ Component, route }">
                    <Suspense>
                        <TransitionByDirection :is-appear="true">
                            <component :is="Component" :key="route.fullPath" />
                        </TransitionByDirection>
                    </Suspense>
                </RouterView>
            </template>
            <template v-else>
                <AppSafeScreen />
            </template>
        </AppLayout>
        <AppBackground />
    </div>
</template>

<style src="@/assets/scss/global/index.scss" />
<style lang="scss" module>
.appContainer .appContent {
    position: relative;
}
</style>
