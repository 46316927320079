import { createRouter, createWebHistory, type RouteRecordNormalized, type RouteRecordRaw } from 'vue-router';
import { middlewareFactory } from './middleware-factory';

import { layoutsMiddleware, authMiddleware } from './middlewares';

declare module 'vue-router' {
    interface RouteMeta {
        layoutName?: string;
        layoutComponent?: object;
        middleware?: Middleware | Middleware[];
    }
}

/**
 * Глобальные middlewares срабатывающие перед каждом роутом
 */
const globalsMiddlewares: Middleware[] = [layoutsMiddleware];

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'index',
        redirect: { name: 'wallet' },
    },
    {
        path: '/wallet',
        component: () => import('@/containers/PageRouter.vue'),
        meta: {
            middleware: [authMiddleware],
        },
        children: [
            {
                path: '',
                name: 'wallet',
                component: () => import('@/pages/wallet/wallet.page.vue'),
            },
            {
                path: 'recovery-phrase',
                name: 'wallet:recovery-phrase',
                component: () => import('@/pages/wallet/recovery-phrase/recovery-phrase.page.vue'),
            },
            {
                path: 'send',
                name: 'wallet:send',
                component: () => import('@/pages/wallet/send/send.page.vue'),
            },
            {
                path: 'receive',
                name: 'wallet:receive',
                component: () => import('@/pages/wallet/receive/receive.page.vue'),
            },
            {
                path: ':nft',
                name: 'wallet:nft',
                component: () => import('@/pages/wallet/nft/nft.page.vue'),
            },
        ],
    },
    {
        path: '/onboarding',
        component: () => import('@/containers/PageRouter.vue'),
        children: [
            {
                path: '',
                name: 'onboarding',
                component: () => import('@/pages/onboarding/onboarding.page.vue'),
            },
            {
                path: 'import',
                name: 'onboarding:import',
                component: () => import('@/pages/onboarding/import/import.page.vue'),
            },
            {
                path: 'create',
                name: 'onboarding:create',
                component: () => import('@/pages/onboarding/create/create.page.vue'),
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    let currentRouteMiddlewares: Middleware[] = getRouteMiddlewareFullPathList(to.matched);
    const middleware: Middleware[] = [...globalsMiddlewares, ...currentRouteMiddlewares];

    const context = {
        to,
        from,
        next,
    };

    return await middleware[0]({
        ...context,
        next: await middlewareFactory(context, middleware, 1),
    });
});

function getRouteMiddlewareList(mw: Middleware | Middleware[]): Middleware[] {
    if (mw) return Array.isArray(mw) ? mw : [mw];
    else return [];
}

function getRouteMiddlewareFullPathList(matched: RouteRecordNormalized[]): Middleware[] {
    let arr = [];
    for (let i = 0; i < matched.length; i++) {
        const currentMatchedMW = getRouteMiddlewareList(matched[i]?.meta?.middleware);
        arr.push(...currentMatchedMW);
    }

    return arr;
}

export default router;
