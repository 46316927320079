export const useTokenConverter = () => {
    const TOKEN_GWEI_CONVERT: Record<TokenName, number> = {
        USDT: Math.pow(10, 6),
        MATIC: Math.pow(10, 18),
        BTC: Math.pow(10, 8),
        ETH: Math.pow(10, 18),
        LINK: Math.pow(10, 18),
        UNT: 1,
    };

    function toGWEI(target: { name: TokenName; WEI: number }): number;
    function toGWEI(target: { name: TokenName; dollars: number }): number;
    function toGWEI(target: { name: TokenName; WEI?: number; dollars?: number }): number {
        if (target?.WEI) return target.WEI / TOKEN_GWEI_CONVERT[target.name];

        if (target?.dollars) {
            const { tokensPerPrices } = useAccountStore();

            return target.dollars / tokensPerPrices[target.name];
        }
    }

    function toWEI(target: { name: TokenName; GWEI: number }): number;
    function toWEI(target: { name: TokenName; dollars: number }): number;
    function toWEI(target: { name: TokenName; GWEI?: number; dollars?: number }): number {
        if (target?.GWEI) return target.GWEI * TOKEN_GWEI_CONVERT[target.name];

        if (target?.dollars) {
            return toGWEI({ name: target.name, dollars: target.dollars }) * TOKEN_GWEI_CONVERT[target.name];
        }
    }

    function toDollar(target: { name: TokenName; WEI: number }): number;
    function toDollar(target: { name: TokenName; GWEI: number }): number;
    function toDollar(target: { name: TokenName; WEI?: number; GWEI?: number }): number {
        const { tokensPerPrices } = useAccountStore();

        if (target?.WEI) return toGWEI({ name: target.name, WEI: target.WEI }) * tokensPerPrices[target.name];
        if (target?.GWEI) return target.GWEI * tokensPerPrices[target.name];

        return 0;
    }

    return { TOKEN_GWEI_CONVERT, toGWEI, toWEI, toDollar };
};
