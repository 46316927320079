import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from '@/App.vue';
import router from '@/router/router';

import { setupStartUpPlugin } from '@/plugins/startup/startup.plugin';

const app = createApp(App);

(async () => {
    app.use(createPinia());
    if (import.meta.env.DEV) app.use(await setupStartUpPlugin());
    app.use(router);

    app.mount('#app');
})();
