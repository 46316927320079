import type { NavigationGuardNext } from 'vue-router';

export const middlewareFactory = (
    context: MiddlewareContext,
    middleware: Middleware[],
    index: number,
): NavigationGuardNext | Promise<NavigationGuardNext> => {
    const nextMiddleware = middleware[index];

    if (!nextMiddleware) {
        return context.next;
    }

    // @ts-ignore
    return async (ctx) => {
        if (ctx) {
            if (typeof ctx == 'object') context.next({ ...ctx });
            else if (typeof ctx == 'boolean') context.next(ctx);
            else if (typeof ctx == 'string') context.next(ctx);
        } else {
            const nextPipeline = await middlewareFactory(context, middleware, index + 1);
            await nextMiddleware({ ...context, next: nextPipeline });
        }
    };
};
