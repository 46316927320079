<script setup lang="ts">
import { SafeArea } from 'capacitor-plugin-safe-area';

const saveArea = ref(0);

const saveAreaCss = computed(() => saveArea.value + 'px');

onBeforeMount(async () => {
    const area = await SafeArea.getSafeAreaInsets();
    saveArea.value = area.insets.top ?? 0;
});
</script>

<template>
    <div :class="$style.safeArea" />
</template>

<style lang="scss" module>
.safeArea {
    height: v-bind(saveAreaCss);
}
</style>
