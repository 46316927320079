import autoAnimate, { getTransitionSizes } from '@formkit/auto-animate';
import { AppAnimationDirection } from '@/common/enums';

type AddRemoveKeyFrames = Record<
    AppAnimationDirection,
    {
        add: KeyFrames;
        remove: KeyFrames;
    }
>;

type KeyFrames = {}[];

const ANIMATION_DURATION = 600;

export function useAutoAnimateHeader(parentElement: HTMLElement) {
    const { currentAnimDirection } = storeToRefs(useAppManageStore());

    autoAnimate(parentElement, (el, action: 'add' | 'remove' | 'remain', oldCoords, newCoords) => {
        let keyframes: KeyFrames = [];

        if (action != 'remain') {
            const addRemoveKeyFrames: AddRemoveKeyFrames = {
                'page-forward': {
                    add: [{ opacity: 0 }, { opacity: 1 }],
                    remove: [{ opacity: 1 }, { opacity: 0 }],
                },
                'page-backward': {
                    add: [{ opacity: 0 }, { opacity: 1 }],
                    remove: [{ opacity: 1 }, { opacity: 0 }],
                },
            };

            keyframes = addRemoveKeyFrames[currentAnimDirection.value][action];
        } else {
            const [widthFrom, widthTo, heightFrom, heightTo] = getTransitionSizes(el, oldCoords, newCoords);

            const headerContainerEl = el as HTMLElement;

            if (heightFrom > heightTo) {
                headerContainerEl.style.height = '60px';

                setTimeout(() => (headerContainerEl.style.height = 'auto'), ANIMATION_DURATION);
            }
        }

        return new KeyframeEffect(el, keyframes, { duration: ANIMATION_DURATION, easing: 'ease' });
    });
}
