<script setup lang="ts">
const { currentThemes } = storeToRefs(useAppManageStore());

const TopGradient = defineAsyncComponent(() => import('@/components/AppBackgrounds/AppBackgroundTopGradient.vue'));
const BottomGradient = defineAsyncComponent(
    () => import('@/components/AppBackgrounds/AppBackgroundBottomGradient.vue'),
);
const Dark = defineAsyncComponent(() => import('@/components/AppBackgrounds/AppBackgroundDark.vue'));
</script>

<template>
    <Transition
        :enter-from-class="$style.enterLeaveClass"
        :enter-active-class="$style.activeClass"
        :leave-active-class="$style.activeClass"
        :leave-to-class="$style.enterLeaveClass"
    >
        <div v-if="currentThemes.length" :class="$style.appBackground">
            <TransitionGroup
                :enter-from-class="$style.enterLeaveClass"
                :enter-active-class="$style.activeClass"
                :leave-active-class="$style.activeClass"
                :leave-to-class="$style.enterLeaveClass"
                appear
            >
                <TopGradient v-if="currentThemes.includes('topGradient')" key="topGradient" />
                <BottomGradient v-if="currentThemes.includes('bottomGradient')" key="bottomGradient" />
                <Dark v-if="currentThemes.includes('dark')" key="dark" />
            </TransitionGroup>
        </div>
    </Transition>
</template>

<style lang="scss" module>
.appBackground {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: zindex.$background;
    overflow: hidden;
}

.enterLeaveClass {
    opacity: 0;
}

.activeClass {
    transition: all 4s ease-in-out;
}
</style>
