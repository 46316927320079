export const useAuthStore = defineStore('auth-store', () => {
    const PINCODE_HASH_SIZE = 6;

    const isAppLoggedIn = ref(false);
    const pincode = ref<string>(null);
    const hashedPincode = useStorage('pc', '');

    const hasAppPincode = computed(() => hashedPincode.value);

    async function setPincode(v: string) {
        const bcryptjs = await _getLibBcrypt();

        const salt = bcryptjs.genSaltSync(PINCODE_HASH_SIZE);
        const hash = bcryptjs.hashSync(v, salt);

        pincode.value = v;
        hashedPincode.value = hash;
    }

    async function comparePincode(target: string) {
        const bcryptjs = await _getLibBcrypt();

        const isValid = await bcryptjs.compare(target, hashedPincode.value);

        if (isValid) pincode.value = target;

        return isValid;
    }

    function _getLibBcrypt() {
        return import('bcryptjs');
    }

    return { pincode: readonly(pincode), isAppLoggedIn, hasAppPincode, setPincode, comparePincode };
});
