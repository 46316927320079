type FuncArgs = Array<any | undefined>;
type FuncResult = any | undefined;

type RequestBody<ArgsT extends FuncArgs, ResT extends FuncResult> = (...args: ArgsT) => ResT | Promise<ResT>;

interface RequestResult<T> {
    success: boolean;
    data: T;
    error: string;
}

export function defineCosmRequest<FuncArgsT extends FuncArgs = [], FuncResultT extends FuncResult = void>(
    func: RequestBody<FuncArgsT, FuncResultT>,
    errorText?: string,
    afterHook?: (args: RequestResult<FuncResultT>) => RequestResult<FuncResultT> | Promise<RequestResult<FuncResultT>>,
) {
    async function request(...args: FuncArgsT): Promise<RequestResult<FuncResultT>> {
        let success = false;
        let data: FuncResultT;
        let error: string;

        try {
            data = await func(...args);
            success = true;
        } catch (err) {
            console.error(errorText);
            console.error(err);
            success = false;
            error = !!errorText ? errorText : err.message;
        }

        if (afterHook) {
            const afterHookData = await afterHook({ success, data, error });

            success = afterHookData.success;
            data = afterHookData.data;
            error = afterHookData.error;
        }

        return { success, data, error };
    }

    return request;
}
